//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-452:_924,_388,_3108,_332,_3408,_4392,_8516,_2874;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-452')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-452', "_924,_388,_3108,_332,_3408,_4392,_8516,_2874");
        }
      }catch (ex) {
        console.error(ex);
      }