
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'className': 'icon icon-search',
                'role': 'presentation',
                'aria-hidden': 'true',
                'xmlns': 'http://www.w3.org/2000/svg',
                'viewBox': '0 0 275 275'
            }, _createElement('g', {}, _createElement('path', { 'd': 'M224.96 85.02c-1.45-14.99-14.08-26.71-29.45-26.71h-74.43l-25.47 29.6h99.9v40.44h-62.32V92.57h-29.6v65.38h91.91c16.35 0 29.6-13.25 29.6-29.6V85.02h-.14Z' }), _createElement('path', { 'd': 'M162.34 0C100.23 0 49.69 50.54 49.69 112.66c0 26.53 9.23 50.95 24.63 70.22L0 257.19 17.81 275l74.32-74.32c19.27 15.4 43.69 24.63 70.22 24.63 62.12 0 112.66-50.54 112.66-112.66S224.46 0 162.34 0Zm0 200.13c-23.68 0-45.19-9.46-60.96-24.8l-1.72-1.72c-15.34-15.77-24.8-37.27-24.8-60.96 0-48.23 39.24-87.48 87.47-87.48s87.48 39.24 87.48 87.48-39.24 87.47-87.48 87.47Z' }))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]