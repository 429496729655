
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', {
                    'className': 'grid-item grid-product',
                    'data-product-handle': this.handle,
                    'data-product-id': this.id
                }, _createElement('div', { 'className': 'grid-item__content' }, _createElement('div', { 'className': 'grid-product__actions' }, _createElement('button', {
                    'type': 'button',
                    'className': 'text-link quick-add-btn js-quick-add-btn',
                    'title': 'Add to cart',
                    'tabIndex': '-1',
                    'data-id': this.variant_ids[0]
                }, _createElement('span', { 'className': 'btn btn--circle btn--icon' }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'className': 'icon icon-plus',
                    'viewBox': '0 0 64 64'
                }, _createElement('path', {
                    'className': 'cls-1',
                    'd': 'M32 9v46m23-23H9'
                })), _createElement('span', { 'className': 'icon__fallback-text' }, 'Add to cart')))), _createElement('a', {
                    'href': this.url,
                    'className': 'grid-item__link'
                }, _createElement('div', { 'className': 'grid-product__image-wrap' }, _createElement('div', { 'className': 'grid__image-ratio grid__image-ratio--square' }, _createElement('img', {
                    'className': 'grid__image-contain lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })), this.image2 ? _createElement('div', {
                    'className': 'grid-product__secondary-image small--hide',
                    'key': '1041'
                }, _createElement('img', {
                    'className': 'lazyautosizes lazyloaded',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2')
                })) : null), _createElement('div', { 'className': 'grid-item__meta' }, _createElement('div', { 'className': 'grid-item__meta-main' }, _createElement('div', mergeProps({ 'className': 'grid-product__title' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', mergeProps({ 'className': 'grid-product__vendor' }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('div', { 'className': 'grid-item__meta-secondary' }, _createElement('div', { 'className': 'grid-product__price' }, _createElement('span', { 'className': 'grid-product__price--current' }, _createElement('span', { 'aria-hidden': 'true' }, this.formatPrice(this.price)))), this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'text',
                    'data-affirm-color': 'blue',
                    'data-learnmore-show': 'false',
                    'key': '1908'
                }) : null)))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []