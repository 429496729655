import shopifyGenericDefaults from '../shopify-generic/config.js';

const ignoreFields = [
  ...shopifyGenericDefaults.Widgets.find((w) => w.name === 'Facets').ignoreFields,
  'category',
];

shopifyGenericDefaults.Widgets.forEach((w) => {
  if (w.type === 'FacetPanel' || w.name === 'FacetDialog') {
    w.ignoreFields = ignoreFields;
  }
});

const baseUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();

  new window.theme.QuickAdd(document.querySelector('.cm_SearchResult'));
}

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['SearchResult'].includes(w.name)),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
  ],
};
